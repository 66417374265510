import React, { useState } from 'react'
import emailjs from '@emailjs/browser'
import { Container, Row, Col } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import './styles/contact.css'

const initialState = {
  name: '',
  email: '',
  message: '',
  telephone: '',
}
export const Contact = (props: any) => {
  const [{ name, email, message, telephone }, setState] = useState(initialState)
  const [show, setShow] = useState(false)

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e: any) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_i9puwmb',
        'template_vrfbl6o',
        e.target,
        'Xvd7H6I_HDcrGEjCL'
      )
      .then(
        (result) => {
          setShow(true)
          clearState()
        },
        (error) => {}
      )
  }
  return (
    <div>
      <div id="contact">
        <Container>
          <Row>
            <Col lg={8}>
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send me an email and I
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          id="telephone"
                          name="telephone"
                          className="form-control"
                          placeholder="Phone number"
                          value={telephone}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows={4}
                      placeholder="Message"
                      value={message}
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="success">
                    <button type="submit" className="btn btn-custom btn-lg">
                      Send Message
                    </button>
                  </div>
                  {show ? (
                    <Alert
                      variant="info"
                      onClose={() => setShow(false)}
                      dismissible
                    >
                      Message sent successfully!
                    </Alert>
                  ) : null}
                </form>
              </div>
            </Col>
            <Col className="contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ paddingRight: '5px' }}
                    />{' '}
                    Address
                  </span>
                  {props.data ? props.data.address : 'loading'}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ paddingRight: '5px' }}
                    />{' '}
                    Phone
                  </span>{' '}
                  {props.data ? props.data.phone : 'loading'}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ paddingRight: '5px' }}
                    />{' '}
                    Email
                  </span>{' '}
                  {props.data ? props.data.email : 'loading'}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      style={{ paddingRight: '5px' }}
                    />{' '}
                    <a
                      className="linkedin"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/conrad-wright/"
                    >
                      Conrad Wright
                    </a>
                  </span>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Pixel. Shout out to Free CSS for the{' '}
            <a href="https://www.free-css.com/">template</a>.
          </p>
        </div>
      </div>
    </div>
  )
}
