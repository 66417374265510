import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './styles/header.css'

export const Header = (props: {
  data: { title: string; paragraph: string }
}) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <Container>
            <Row>
              <div className="intro-text">
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{' '}
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </header>
  )
}
