import React, { useEffect, useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { Navigation } from './components/Navigation'
import { Header } from './components/Header'
import { Services } from './components/Services'
import { About } from './components/About'
import { Team } from './components/Team'
import { Contact } from './components/Contact'
import JsonData from './data/data.json'

import './components/styles/style.css'

function App() {
  const [landingPageData, setLandingPageData] = useState<any>({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div className="App">
      <Navigation />
      <Header data={landingPageData.Header} />
      <Services data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  )
}

export default App
