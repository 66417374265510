import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDesktop,
  faCreditCard,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons'
import './styles/services.css'

export const Services = (props: {
  data: Array<{ title: string; icon: string; text: string }>
}) => {
  const iconMapper: any = {
    0: faDesktop,
    1: faCreditCard,
    2: faPeopleGroup,
  }

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Services</h2>
        </div>
        <div className="row features-container">
          {props.data
            ? props.data.map((d: any, i: number) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-xs-4 col-md-4 feature"
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={iconMapper[i]}
                    className="services-icons"
                  />
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
