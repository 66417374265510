import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'react-bootstrap'
import './styles/about.css'

export const About = (props: any) => {
  return (
    <div id="about">
      <Container>
        <Row className="about-row">
          <Col>
            {' '}
            <img
              src={require('../img/about.jpg')}
              className="img-responsive"
              alt=""
            />{' '}
          </Col>
          <Col className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Me</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h3>Guiding Principles</h3>
              <Row className="list-style">
                <Col>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d: string, i: number) => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: '#5ca9fb' }}
                            />
                            <li key={`${d}-${i}`}>{d}</li>
                          </div>
                        ))
                      : 'loading'}
                  </ul>
                </Col>
                <Col>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d: string, i: number) => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: '#5ca9fb' }}
                            />
                            <li key={`${d}-${i}`}>{d}</li>
                          </div>
                        ))
                      : 'loading'}
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
