import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'

export const Navigation = () => {
  return (
    <Navbar bg="light" data-bs-theme="light">
      <Container style={{ flexDirection: 'row' }}>
        <Navbar.Brand href="#home">Pixel</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="#features">Services</Nav.Link>
          <Nav.Link href="#about">About Me</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  )
}
