import React from 'react'
import './styles/team.css'

export const Team = (props: any) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Your Small Business Technologist</h2>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d: any, i: number) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {' '}
                    <img
                      src={require('../img/team/portrait.jpeg')}
                      alt="..."
                      className="team-img"
                    />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
